<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="lg"
      hide-footer
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="text-center flex-grow-1 text-r-20">Serial</div>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="bg-white border-red">
        <div class="d-flex justify-content-start align-items-center">
          <InputText
            v-model="filter.search"
            textFloat=""
            type="text"
            name="search_serial"
            placeholder="ค้นหา Serial"
            faIcon="search"
            className="my-2"
            :disabled="isBusy"
            @onEnter="(val) => handleSearch(val)"
            @onClickIcon="(val) => handleSearch(val)"
          />
          <div>
            <img
              src="@/assets/images/icons/bin.png"
              alt="delete"
              class="pointer delete-icon"
              center
              width="32"
              @click="handleSearch('')"
            />
          </div>
        </div>
        <b-row>
          <b-col>
            <b-table
              v-if="!isMobile"
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
              sticky-header
            >
              <template v-slot:cell(select)="data">
                <b-button
                  variant="outline-primary-color"
                  class="btn-modal btn-hover"
                  @click="unselectRow()"
                  :disabled="isBusy"
                  v-if="filter.select_serial.includes(data.item.id)"
                >
                  ยกเลิก
                </b-button>
                <b-button
                  variant="primary-color"
                  class="btn-modal btn-hover"
                  @click="selectRow(data.item)"
                  :disabled="isBusy"
                  v-else
                >
                  เลือก
                </b-button>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <div v-else>
              <div class="py-2 detail-panel mb-5 mt-2">
                <div
                  class="product-card"
                  v-for="(c, index) in items"
                  :key="index"
                  @click="selectRow(c)"
                >
                  <div class="product-card-detail">
                    <div>
                      <strong>{{ c.code || "-" }}</strong>
                      <p class="text-general">{{ c.name }}</p>
                    </div>
                    <div>
                      <p>
                        {{ c.promotion_value | numeral("0,0.00") }}
                        {{ c.discount_type === "percent" ? "%" : "บาท" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="p-2 m-2 bg-white no-data-item"
                  v-if="items.length == 0"
                >
                  ไม่พบข้อมูล
                </div>
              </div>
            </div>
            <Pagination
              @handleChangeTake="(val) => handleChangeTake(val, 'booth')"
              :pageOptions="pageOptions"
              :filter="filter"
              :rows="rows"
              @pagination="(val) => pagination(val)"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalNote ref="modalNote" :branchId="branchId" />
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";
import ModalNote from "@/components/customer/ModalNote";
import Pagination from "@/components/Pagination";
export default {
  components: {
    InputTextArea,
    OtherLoading,
    InputText,
    ModalNote,
    Pagination,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      user: null,
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "serial",
          label: "Serial",
        },
        {
          key: "price",
          label: "Price",
        },
      ],
      select_serial: null,    
       now: null,
      items: [],
      isBusy: false,
      rows: 0,

      selecedHeaderDiscountIdList: [],
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      product_id: "",
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  methods: {
    async getSerial() {
      this.isLoading = true;
      this.isBusy = true
      await this.axios
        .post(
          `${this.$baseUrl}/product/get_product_serial/${this.product_id}`,
          this.filter
        )
        .then(async (data) => {
          if (data.result == 1) {
            this.items = data.detail.data;
            this.rows = data.detail.count;

            this.isLoading = false;
          }
        });

      this.isBusy = false;
    },
    async show(productId) {
      this.product_id = productId;
      this.showModal = true;
      await this.getSerial()
    },
    hide() {
      this.showModal = false;
    },
    async selectRow(item) {
      this.select_serial = item.id;
      await this.getSerial()
    },
    async unselectRow() {
      his.select_serial = null;
    },
    handleSearch(search, key) {
      this.filter.page = 1;
      this.filter.search = search;
      this.getSerial();
    },
    handleChangeTake(value, key) {
      this.filter.page = 1;
      this.filter.take = value;
    },
    pagination(page, key) {
      this.filter.page = page;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.product-card {
  min-height: 88px;
  cursor: pointer;
  color: #333333;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;

  .product-card-detail {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.no-data-item {
  min-height: 88px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #bdbdbd;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}

.delete-icon {
  margin-left: 8px;
  // margin-bottom: 13px;
}
</style>
